import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIPresentationTemplateBanner: GAParameterMakerFnType<
  'ACTION_CLICK_AI_PRESENTATION_TEMPLATE_BANNER'
> = (payload) => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '템플릿패널_프레젠테이션타입',
  location3: 'AI프레젠테이션배너',
  location4: payload.data.templateKeyword,
  action1: '클릭',
  label1: `에디터_템플릿패널_AI프레젠테이션배너_${payload.data.templateKeyword}`,
});
