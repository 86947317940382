import { useGetCurrentUserSnapshotValue } from '@business/user/_services/_hooks/useGetCurrentUserSnapshotValue';
import { useMutation, useQuery, useQueryClient } from '@configs/react-query';
import { queryKeys } from '@configs/react-query/queryKeys';
import {
  getRecentlyViewedTemplate,
  GetRecentlyViewedTemplateParams,
  postRecentlyViewedTemplate,
  PostRecentlyViewedTemplateBodyDataItem,
} from '@miri-unicorn/miricanvas-api-client';
import { LogUtil } from '@miri-unicorn/miricanvas-utils/functions';
import { RecentlyViewedTemplateObj } from '../_types/RecentlyViewedTemplateTypes';

export const usePostRecentlyViewedTemplateMutation = () => {
  const { mutateAsync } = useMutation(postRecentlyViewedTemplate);
  const queryClient = useQueryClient();
  const { getCurrentUserSnapshotValue } = useGetCurrentUserSnapshotValue();

  const postRecentlyViewedTemplateMutation = async ({
    data,
    onSuccess,
    maxSliceCount,
  }: {
    data: RecentlyViewedTemplateObj[];
    onSuccess?: () => void;
    maxSliceCount?: number;
  }) => {
    //  1) 새로운 리스트를 DB에 저장한다.

    const newData = data
      .map((item) => {
        return {
          idx: item.idx,
          purpose: item.displayType.join(','),
          timestamp: item.timestamp,
        };
      })
      .slice(0, maxSliceCount) satisfies PostRecentlyViewedTemplateBodyDataItem[];

    // 2) 로컬스토리지에 저장된 최근 본 템플릿 리스트가 존재하지 않는 경우 리턴
    if (newData.length === 0) {
      return;
    }

    const result = await mutateAsync({ data: newData });

    // 3) 실패 시 리턴
    if (result.data === 0) {
      return;
    }

    const { accountId } = getCurrentUserSnapshotValue();

    if (!accountId) {
      LogUtil.warn.all('accountId is not exist');
    } else {
      // 3) getRecentlyViewedTemplate API를 새롭게 호출하여, 리스트를 업데이트 시킨다
      await queryClient.invalidateQueries({
        queryKey: queryKeys.getRecentlyViewedTemplate({
          accountId: accountId,
        }),
      });

      onSuccess?.();
    }
  };

  return { postRecentlyViewedTemplateMutation };
};

export const useGetRecentlyViewedTemplateQuery = (
  params: GetRecentlyViewedTemplateParams,
  enabled?: boolean
) => {
  return useQuery({
    queryKey: queryKeys.getRecentlyViewedTemplate(params),
    queryFn: () => getRecentlyViewedTemplate(params),
    options: {
      enabled,
    },
  });
};
