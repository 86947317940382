import { PhotoExtraEditEventCommonPayload } from '@configs/google_analytics/features/photo_extra_edit/_types/photoExtraEditEventCommonPayload';

export const getPhotoExtraEditEventCommonParam = (payload: PhotoExtraEditEventCommonPayload) => {
  const {
    teamIdx,
    accountId,
    planType,
    languageSetting,
    designIdx,
    designHistoryIdx,
    designPageIdx,
  } = payload;

  return {
    team_idx: teamIdx,
    account_id: accountId,
    plan_type: planType,
    language_setting: languageSetting,
    design_idx: designIdx,
    design_history_idx: designHistoryIdx,
    design_page_idx: designPageIdx,
  };
};
