import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { languageKorNameToKorMap } from '@configs/i18next/language_util';
import { getTemplateSearchLanguageSnapshotValue } from '@ui/design/_common/design_resource_panel/_stores/template/useTemplateSearchLanguage';
import { ActionClickTemplateSearchLanguageButtonReturnPayload } from '../types/TemplatePanelPayload';

export const makeGAParamsActionClickTemplate: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_ITEM'
> = (payload) => {
  return {
    log_type: 'action_template',
    event: 'action_template',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    action_item: payload.data.action_item,
    action_item_type: 'TEMPLATE',
    search_keyword_category: payload.data.keywordCategoryName,
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_color: payload.data.color,
    search_shape: payload.data.search_shape,
    search_language: payload.data.search_language,
    search_template_type: payload.data.search_template_type ?? 'TEMPLATE',
    search_free: payload.data.search_free,
    priority: payload.data.priority,
    priority2: payload.data.priority2,
    source_tab: payload.data.source_tab,
  };
};

export const makeGAParamsActionSearchTemplate: GAParameterMakerFnType<
  'ACTION_SEARCH_TEMPLATE_ITEM'
> = (payload) => {
  return {
    log_type: 'search_template',
    event: 'search_template',
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_color: payload.data.color,
    search_shape: payload.data.search_shape,
    search_language: payload.data.search_language,
    search_template_type: payload.data.search_template_type ?? 'TEMPLATE',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_free: payload.data.search_free,
    beginning_template: payload.data.beginning_template,
    source_tab: payload.data.source_tab,
  };
};

export const makeGAParamsActionClickTemplateSearchLanguageButton: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_SEARCH_LANGUAGE_BUTTON'
> = () => {
  const { search_language } = getTemplateSearchLanguageSnapshotValue();
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '템플릿',
    location3: '검색결과언어',
    location4: languageKorNameToKorMap.get(search_language ?? 'ko'),
    action1: '클릭',
    label1: `에디터_템플릿_검색결과언어_${languageKorNameToKorMap.get(search_language ?? 'ko')}`,
  } satisfies ActionClickTemplateSearchLanguageButtonReturnPayload;
};

export const makeGAParamsActionViewTemplateItem: GAParameterMakerFnType<
  'ACTION_VIEW_TEMPLATE_ITEM'
> = (payload) => {
  return {
    log_type: 'view_template',
    event: 'view_template',
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_color: payload.data.color,
    search_shape: payload.data.search_shape,
    search_language: payload.data.search_language,
    search_template_type: payload.data.search_template_type ?? 'TEMPLATE',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    beginning_template: payload.data.beginning_template,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_free: payload.data.search_free,
    view_item_key: payload.data.view_item_key.join(', '),
    view_item_type: payload.data.view_item_type.join(', '),
    view_load_num: payload.data.view_load_num.toString(),
    source_tab: payload.data.source_tab,
  };
};

export const makeGAParamsActionClickTemplateSearchFilterButton: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_SEARCH_FILTER_BUTTON'
> = () => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '템플릿',
    location3: '검색필터',
    action1: '클릭',
    label1: '에디터_템플릿_검색필터',
  };
};
