import { EventType } from '@event/_types/EventType';
import { InpaintingGAParamMap } from '@configs/google_analytics/features/photo_extra_edit/inpainting/_types/inpaintingGAParamMap';
import { refinePhotoExtraEditGAParameters } from '@configs/google_analytics/features/photo_extra_edit/_utils/refinePhotoExtraEditGAParameters';
import { InpaintingGTMTriggerEventParam } from '@configs/google_analytics/features/photo_extra_edit/inpainting/_types/inpaintingGTMTriggerEventParam';

export const createInpaintingGAParamRefiner = <T extends EventType>() => {
  return (params: T extends keyof InpaintingGAParamMap ? InpaintingGAParamMap[T] : never) => {
    return refinePhotoExtraEditGAParameters<InpaintingGTMTriggerEventParam>(params);
  };
};
