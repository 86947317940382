import { AbBucketingWithDecision } from '@configs/ab-test/AbBucketingWithDecision';
import { DatadogProvider } from '@configs/datadog/DatadogProvider';
import { isWebView } from '@configs/devices/device_util';
import { BuildEnv } from '@configs/environments/BuildEnv';
import { ServerEnvProvider } from '@configs/environments/ServerEnvProvider';
import { API_URL } from '@configs/environments/base_url';
import { appWithTranslation } from '@configs/i18next';
import { RootProvider } from '@configs/jotai/RootProvider';
import { QueryProvider } from '@configs/react-query/QueryProvider';
import { ServiceWorkerInitializer } from '@configs/service_worker/ServiceWorkerInitializer';
import { initApiClient } from '@miri-unicorn/miricanvas-api-client';
import { PopoverProvider } from '@miri-unicorn/miricanvas-ds';
import { getSubdomain } from '@miri-unicorn/miricanvas-utils/functions';
import { DefaultPageProps } from '@routing/ssr/hydration/_types/defaultPageProps';
import { MicanStyledProvider } from '@styles/MicanStyledProvider';
import { ConfirmModalProvider } from '@ui/common/dialog_provider/confirm_modal_provider/ConfirmModalProvider';
import { SnackbarProvider } from '@ui/common/dialog_provider/snackbar_provider/SnackbarProvider';
import { AfterLoginInitializer } from '@ui/common/initializer/AfterLoginInitializer';
import { PopupProvider } from '@ui/common/popup_provider/PopupProvider';
import { useIsomorphicLayoutEffect } from '@utils/hooks';
import nextI18NextConfig from 'next-i18next.config';
import type { AppProps } from 'next/app';
import { RecruitmentNotice } from '@ui/common/RecruitmentNotice.tsx';
import { dynamicIdle } from '@utils/functions/dynamic_util';
import { PerformanceTracker } from '@utils/components/performance/PerformanceTracker';

const DevToolsContainer = dynamicIdle(
  () => import('@ui/common/dev_tools/DevToolsContainer').then((mod) => mod.DevToolsContainer),
  {
    ssr: false,
  }
);

const App = ({ Component, pageProps }: AppProps<DefaultPageProps>) => {
  useIsomorphicLayoutEffect(() => {
    initApiClient({
      baseURL: API_URL,
      domain: getSubdomain(location.hostname),
      withCredentials: true,
      language: pageProps.language,
      isApp: isWebView(),
    });
  }, [pageProps]);

  return (
    <>
      <PerformanceTracker />
      <RootProvider>
        <QueryProvider dehydratedState={pageProps.dehydratedState}>
          <DatadogProvider>
            <MicanStyledProvider
              isDarkMode={pageProps.isDarkMode}
              deviceType={pageProps.deviceType}>
              <ServerEnvProvider serverEnv={pageProps.serverEnv}>
                <PopupProvider>
                  <PopoverProvider>
                    <ConfirmModalProvider>
                      <SnackbarProvider>
                        <AfterLoginInitializer />
                        <AbBucketingWithDecision lang={pageProps.language} />
                        <Component {...pageProps} />
                        {!BuildEnv.IS_PRODUCTION && <DevToolsContainer />}
                        {!BuildEnv.IS_DEVELOP && pageProps.language === 'ko' && (
                          <RecruitmentNotice />
                        )}
                      </SnackbarProvider>
                    </ConfirmModalProvider>
                  </PopoverProvider>
                </PopupProvider>
              </ServerEnvProvider>
              {!BuildEnv.IS_PRODUCTION && <ServiceWorkerInitializer />}
            </MicanStyledProvider>
          </DatadogProvider>
        </QueryProvider>
      </RootProvider>
    </>
  );
};

export default appWithTranslation(App, nextI18NextConfig);
