import { InpaintingGAParameterMakerFnType } from '@configs/google_analytics/types/inpaintingGAParameterMakerFnType';
import { getPhotoExtraEditEventCommonParam } from '@configs/google_analytics/features/photo_extra_edit/_utils/getPhotoExtraEditEventCommonParam';
import { getPhotoExtraEditorOpenImageUrl } from '@configs/google_analytics/features/photo_extra_edit/_makers/getPhotoExtraEditorOpenImageUrl';
import { PhotoExtraEditEventCommonPayload } from '@configs/google_analytics/features/photo_extra_edit/_types/photoExtraEditEventCommonPayload';
import { createInpaintingGAParamRefiner } from '@configs/google_analytics/features/photo_extra_edit/inpainting/_utils/createInpaintingGAParamRefiner';

const makeCommonParams = (payload: PhotoExtraEditEventCommonPayload) => {
  return {
    log_type: 'ai_inpaint' as const,
    event: 'ai_inpaint' as const,
    opened_image: getPhotoExtraEditorOpenImageUrl(),
    ...getPhotoExtraEditEventCommonParam(payload),
  };
};

export const makeGAParamsOpenInpaintingEditor: InpaintingGAParameterMakerFnType<
  'ACTION_OPEN_INPAINTING_EDITOR'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_OPEN_INPAINTING_EDITOR'>()({
    ...makeCommonParams(payload.data),
    opened_image: payload.data.openedImageUrl,
    action_label: '진입',
    action_depth: '에디터',
    action_type: '클릭',
  });
};

export const makeGAParamsChangeInpaintingBrushType: InpaintingGAParameterMakerFnType<
  'ACTION_CHANGE_INPAINTING_BRUSH_TYPE'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CHANGE_INPAINTING_BRUSH_TYPE'>()({
    ...makeCommonParams(payload.data),
    action_label: payload.data.brushType,
    action_depth: '부분생성',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingCanvas: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_CANVAS'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_CANVAS'>()({
    ...makeCommonParams(payload.data),
    action_label: payload.data.brushType,
    action_depth: '부분생성',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingPromptResetButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_PROMPT_RESET_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_PROMPT_RESET_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '입력초기화',
    action_depth: '부분생성',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingGenerateButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_GENERATE_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_GENERATE_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '부분생성',
    action_depth: '부분생성',
    action_type: '클릭',
    positive_prompt: payload.data.textPrompt,
    selection_count: payload.data.selectionCount,
    deselection_count: payload.data.deselectionCount,
    etc1: payload.data.brushSize.toString(),
  });
};

export const makeGAParamsClickInpaintingCancelButtonInControlPanel: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_CANCEL_BUTTON_IN_CONTROL_PANEL'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_CANCEL_BUTTON_IN_CONTROL_PANEL'>()(
    {
      ...makeCommonParams(payload.data),
      action_label: payload.data.cancelType,
      action_depth: payload.data.cancelTiming,
      action_type: '클릭',
      generated_image: payload.data.imagePromptUrl,
    }
  );
};

export const makeGAParamsClickInpaintingResultApplyButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_RESULT_APPLY_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_RESULT_APPLY_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '적용',
    action_depth: '부분생성',
    action_type: '클릭',
    generated_image: payload.data.imagePromptUrl,
  });
};

export const makeGAParamsClickInpaintingRegenerateButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_REGENERATE_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_REGENERATE_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '다시생성',
    action_depth: '부분생성',
    action_type: '클릭',
    generated_image: payload.data.imagePromptUrl,
  });
};

export const makeGAParamsClickInpaintingHeaderBackButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_HEADER_BACK_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_HEADER_BACK_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '돌아가기',
    action_depth: '헤더',
    action_type: '클릭',
  });
};

export const makeGAParamsUndoRedoInInpaintingEditor: InpaintingGAParameterMakerFnType<
  'ACTION_UNDO_REDO_IN_INPAINTING_EDITOR'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_UNDO_REDO_IN_INPAINTING_EDITOR'>()({
    ...makeCommonParams(payload.data),
    action_label: payload.data.undoRedoType,
    action_depth: '헤더',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingHeaderResetButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_HEADER_RESET_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_HEADER_RESET_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '초기화',
    action_depth: '헤더',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingHeaderShowOriginalImageButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_HEADER_SHOW_ORIGINAL_IMAGE_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_HEADER_SHOW_ORIGINAL_IMAGE_BUTTON'>()(
    {
      ...makeCommonParams(payload.data),
      action_label: '원본이미지보기',
      action_depth: '헤더',
      action_type: '클릭',
      generated_image: payload.data.imagePromptUrl,
    }
  );
};

export const makeGAParamsClickInpaintingHeaderEditCompleteButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_HEADER_EDIT_COMPLETE_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_HEADER_EDIT_COMPLETE_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '편집완료',
    action_depth: '헤더',
    action_type: '클릭',
    generated_image: payload.data.imagePromptUrl,
  });
};

export const makeGAParamsZoomInInpaintingEditor: InpaintingGAParameterMakerFnType<
  'ACTION_ZOOM_INPAINTING_EDITOR'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_ZOOM_INPAINTING_EDITOR'>()({
    ...makeCommonParams(payload.data),
    action_label: payload.data.zoomType,
    action_depth: '플로팅버튼',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingCancelButtonInCanvas: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_CANCEL_BUTTON_IN_CANVAS'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_CANCEL_BUTTON_IN_CANVAS'>()({
    ...makeCommonParams(payload.data),
    action_label: '중앙취소버튼',
    action_depth: '생성중',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingHeaderBackAndNotApplyButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_HEADER_BACK_AND_NOT_APPLY_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_HEADER_BACK_AND_NOT_APPLY_BUTTON'>()(
    {
      ...makeCommonParams(payload.data),
      action_label: '적용하지않고나가기',
      action_depth: '나가기',
      action_type: '클릭',
      generated_image: payload.data.imagePromptUrl,
    }
  );
};

export const makeGAParamsClickInpaintingHeaderBackAndApplyButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_HEADER_BACK_AND_APPLY_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_HEADER_BACK_AND_APPLY_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '적용하기',
    action_depth: '나가기',
    action_type: '클릭',
    generated_image: payload.data.imagePromptUrl,
  });
};

export const makeGAParamsClickInpaintingHeaderBackAndCloseButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_HEADER_BACK_AND_CLOSE_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_HEADER_BACK_AND_CLOSE_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '끄기',
    action_depth: '나가기',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingGuideButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_GUIDE_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_GUIDE_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: '사용팁',
    action_depth: '부분생성',
    action_type: '클릭',
  });
};

export const makeGAParamsClickInpaintingGuideCloseButton: InpaintingGAParameterMakerFnType<
  'ACTION_CLICK_INPAINTING_GUIDE_CLOSE_BUTTON'
> = (payload) => {
  return createInpaintingGAParamRefiner<'ACTION_CLICK_INPAINTING_GUIDE_CLOSE_BUTTON'>()({
    ...makeCommonParams(payload.data),
    action_label: payload.data.closeType,
    action_depth: '사용팁',
    action_type: '클릭',
  });
};
