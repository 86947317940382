import { RefinedPhotoExtraEditParam } from '@configs/google_analytics/features/photo_extra_edit/_types/refinedPhotoExtraEditParam';

export const refinePhotoExtraEditGAParameters = <T extends object>(
  params: Partial<T>
): RefinedPhotoExtraEditParam<T> => {
  const keys = Object.keys(params) as Array<keyof T>;
  const refinedParams = keys.reduce((acc, key) => {
    const value = params[key];
    if (key === 'account_id' && value === 0) {
      acc[key] = undefined;
    } else if (typeof value === 'string') {
      acc[key] = value.replace(/\s/g, '') || undefined;
    } else {
      acc[key] =
        value !== undefined && value !== null ? value.toString().replace(/\s/g, '') : undefined;
    }
    return acc;
  }, {} as RefinedPhotoExtraEditParam<T>);

  return {
    ...refinedParams,
  };
};
