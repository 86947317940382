import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickTemplateTypeFilterButton: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_TYPE_FILTER_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: payload.data.location1,
    location2: '템플릿',
    location3: '타입필터',
    action1: '클릭',
    label1: `${payload.data.location1}_템플릿_타입필터`,
  };
};
