import { DesignResourceType } from '@miri-unicorn/miricanvas-api-client';

/**
 * 좌측 탭 바의 메뉴 목록
 * @important 이 변수에서 타입,map 정보 이끌어냄
 * 순서 있음!!!!
 *
 *
 * 템플릿페이지, 좌패널 템플릿에서 사용하는 최근사용 키워드가 동일한 곳을 참조하고 있어서,
 * 'TEMPLATE'이라는 값을 함께 사용하도록 DesignResourceType.TEMPLATE을 사용.
 */
export const DESIGN_RESOURCE_PANEL_MENU_LIST = [
  // 템플릿
  DesignResourceType.TEMPLATE,
  // 작업공간
  'WORKSPACE',
  // 사진
  DesignResourceType.PICTURE,
  // 업로드
  'UPLOAD',
  // 요소
  'ELEMENTS',
  // 텍스트
  DesignResourceType.TEXT,
  // AI 드로잉
  'AIDRAWING',
  // 기여자 탭
  'CREATOR',
  // 데이터
  'DATA',
  // 동영상
  DesignResourceType.VIDEO,
  // 오디오
  DesignResourceType.AUDIO,
  // 로고
  'LOGO',
  // 테마
  'THEME',
  // 배경
  'BACKGROUND',
  // 찜
  'FAVORITES',
  // QR 바코드
  'QRBARCODE',
  // 'LOGOS',
  // 일본 튜토리얼 진입점
  'TUTORIAL',
] as const;

/** 좌측 탭 바의 메뉴 타입 */
export type DesignResourcePanelMenuType = (typeof DESIGN_RESOURCE_PANEL_MENU_LIST)[number];

/** 좌측 탭 바 메뉴 타입의 텍스트 */
export const DESIGN_RESOURCE_PANEL_MENU_LIST_MAP = {
  TEMPLATE: '템플릿',
  WORKSPACE: '작업공간',
  PICTURE: '사진',
  UPLOAD: '업로드',
  ELEMENTS: '요소',
  TEXT: '텍스트',
  CREATOR: '기여자 탭',
  VIDEO: '동영상',
  AUDIO: '오디오',
  AIDRAWING: 'AI 드로잉',
  LOGO: '로고',
  THEME: '테마',
  BACKGROUND: '배경',
  FAVORITES: '찜',
  QRBARCODE: 'QR 바코드',
  DATA: '데이터',
  // 일본 튜토리얼 진입점
  TUTORIAL: '튜토리얼',
} satisfies Record<DesignResourcePanelMenuType, string>;

export const getDesignResourcePanelMenuTypeText = (menuType: DesignResourcePanelMenuType) => {
  return DESIGN_RESOURCE_PANEL_MENU_LIST_MAP[menuType];
};

/** 좌측 탭의 바의 메뉴 목록 enum */
export const designResourcePanelBarMenuEnum = {
  TEMPLATE: 'TEMPLATE',
  CREATOR: 'CREATOR',
  WORKSPACE: 'WORKSPACE',
  PICTURE: 'PICTURE',
  ELEMENTS: 'ELEMENTS',
  TEXT: 'TEXT',
  AUDIO: 'AUDIO',
  UPLOAD: 'UPLOAD',
  BACKGROUND: 'BACKGROUND',
  VIDEO: 'VIDEO',
  AIDRAWING: 'AIDRAWING',
  FAVORITES: 'FAVORITES',
  LOGO: 'LOGO',
  QRBARCODE: 'QRBARCODE',
  THEME: 'THEME',
  DATA: 'DATA',
  // 일본 온보딩 튜토리얼 메뉴
  TUTORIAL: 'TUTORIAL',
} as const satisfies Record<DesignResourcePanelMenuType, DesignResourcePanelMenuType>;
