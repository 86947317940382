import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsActionClickTemplateKeywordBanner: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_KEYWORD_BANNER'
> = ({ data: { location2, location4 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '템플릿페이지',
    location2,
    location3: 'CTA',
    location4,
    action1: '클릭',
    label1: '템플릿페이지_맞춤배너_CTA_클릭',
  } satisfies ActionTemplateKeywordBannerReturnPayload;
};

export const makeGAParamsActionViewTemplateKeywordBanner: GAParameterMakerFnType<
  'ACTION_VIEW_TEMPLATE_KEYWORD_BANNER'
> = ({ data: { location2, location4 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '템플릿페이지',
    location2,
    location3: undefined,
    location4,
    action1: '뷰',
    label1: '템플릿페이지_맞춤배너_뷰',
  } satisfies ActionTemplateKeywordBannerReturnPayload;
};

export type ActionTemplateKeywordBannerPayload = {
  location2:
    | '프레젠테이션'
    | '포스터'
    | '인포그래픽'
    | '로고'
    | '명함'
    | '초대장'
    | '프레젠테이션/BUSINESS'
    | '프레젠테이션/SIMPLE'
    | '프레젠테이션/EDUCATION'
    | '프레젠테이션/COOL'
    | '프레젠테이션/PORTFOLIO'
    | '프레젠테이션/CUTE'
    | '프레젠테이션/PROFESSIONAL'
    | '프레젠테이션/ADVERTISING'
    | '프레젠테이션/COMPANY'
    | '프레젠테이션/MARKETING'
    | '프레젠테이션/SCIENCE'
    | '프레젠테이션/ONBOARDING'
    | '프레젠테이션/ART'
    | '프레젠테이션/VINTAGE'
    | '프레젠테이션/TECHNOLOGY'
    | '프레젠테이션/MEDICAL'
    | '프레젠테이션/CONFERENCE'
    | '프레젠테이션/PRODUCT'
    | '프레젠테이션/ABOUT_ME'
    | '로고/RESTAURANT'
    | '로고/CAFE'
    | '로고/CIRCULAR'
    | '로고/FOOD'
    | '로고/DRINK'
    | '로고/LANDSCAPING'
    | '로고/WEDDING'
    | '로고/LUXURY'
    | '로고/BLACK'
    | '로고/GOLD'
    | '로고/CORPORATE'
    | '로고/FASHION'
    | '로고/BEAUTY'
    | '로고/ORGANIC'
    | '로고/CLOTHING'
    | '로고/GYM'
    | '로고/PLANT'
    | '로고/BAKERY'
    | '로고/MEDICAL'
    | '로고/TREE'
    | '로고/GAMING'
    | undefined;
  location4: '2depth' | '3depth' | undefined;
};

type ActionTemplateKeywordBannerReturnPayload = GAParam<GTMTriggerEventParams>;
